"use client";
import { useState } from 'react';
import { FaCopy } from 'react-icons/fa';

const CopyToClipboard = ({ text }) => {
    const [copySuccess, setCopySuccess] = useState('');

    const copyToClipboard = () => {
        navigator.clipboard.writeText(text)
            .then(() => {
                setCopySuccess('Copied!');
                setTimeout(() => setCopySuccess(''), 2000); // remove success message after 2 seconds
            })
            .catch(err => console.log('Something went wrong', err));
    };

    return (
        <div className="flex items-center gap-2 cursor-pointer text-2xl font-semibold text-postlyBlue" onClick={copyToClipboard}>
            <FaCopy />
            {copySuccess && <span className="text-postlyGreen ml-2">{copySuccess}</span>}
        </div>
    );
};

export default CopyToClipboard;


