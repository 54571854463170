"use client";

import React from "react";
import Image from "next/image";
import SiteMetaData from "@/components/SiteMetaData";
import StartNowDark from "@/assets/svgs/StartNowDark.svg";
import StartNowLight from "@/assets/svgs/StartNowLight.svg";

const GetStartedNow = ({ className, theme = "light", ...props }) => {
  const startNowTheme = theme === "dark" ? StartNowDark : StartNowLight;

  return (
    <button
      {...props}
      className={`flex flex-wrap items-center focus:outline-none ${className}`}
      aria-label="Get Started Now"
      onClick={() => window.open(SiteMetaData.register, "_blank", "noopener")}
    >
      <Image src={startNowTheme} alt="Start Now Icon" className="h-auto w-auto" />
    </button>
  );
};

export default GetStartedNow;
